<template>
    <div class="p-grid">
        <div class="p-col-12">
            <div class="card">
                <Toast />
                <h5>{{ $t('menu.paidTickets') }}</h5>
                <div class="p-grid">
                    <div class="p-col-12 p-md-2">
                        <span class="p-float-label">
                            <Calendar ref="startDateCalendar" style="width: 100%" dateFormat="dd.mm.yy" :showTime="true" hourFormat="24" :showIcon="true" :showButtonBar="true" v-model="startDateModel"></Calendar>
                            <label>{{ $t('sportsbets.startDate') }}</label>
                        </span>
                    </div>
                    <div class="p-col-12 p-md-2">
                        <span class="p-float-label">
                            <Calendar style="width: 100%" dateFormat="dd.mm.yy" :showTime="true" hourFormat="24" :showIcon="true" :showButtonBar="true" v-model="endDateModel"></Calendar>
                            <label>{{ $t('sportsbets.endDate') }}</label>
                        </span>
                    </div>
                    <div class="p-col-12 p-md-2">
                        <span class="p-float-label">
                            <Dropdown :showClear="true" style="width: 100%" v-model="productModel" :options="products" optionLabel="name" :filter="!this.isMobile()" />
                            <label>{{ $t('dropdown.placeholder.product') }}</label>
                        </span>
                    </div>
                    <div class="p-col-12 p-md-2">
                        <span class="p-float-label">
                            <InputText type="number" step="1" style="width: 100%" v-model="daysNo" />
                            <label>{{ $t('reports.dayDifference') }}</label>
                        </span>
                    </div>
                    <div class="p-col-6 p-md-3 p-text-right"><Button type="button" :loading="isReportLoading" :label="$t('buttons.show')" class="p-button-raised p-button-success" @click="showReport()" /></div>
                    <div class="p-col-6 p-md-1 p-text-right">
                        <Button type="button" :label="$t('buttons.saveXls')" :loading="isReportDownloading" icon="pi pi-download" class="p-button-raised p-button-info" @click="saveXls()" />
                    </div>
                    <transition-group name="p-message" tag="div" class="p-col-12">
                        <Message :key="errorKeyCount" :severity="'error'" v-show="showError">{{ $t('alerts.generalError') }}</Message>
                    </transition-group>
                </div>
                <div class="card" v-if="showReportData">
                    <DataTable :value="reportData" ref="datatable" responsiveLayout="scroll">
                        <Column>
                            <template #body="slotProps">
                                <span class="indexColumn">{{ slotProps.index + 1 }}</span>
                            </template>
                        </Column>
                        <Column :header="$t('bets.ticketSerial')" field="ticketSerial" :sortable="true"> </Column>
                        <Column field="ticketDate" :header="$t('sportsbets.date')" :sortable="true">
                            <template #body="slotProps">{{ formatCSDateAndTime(slotProps.data.ticketDate) }} </template>
                        </Column>
                        <Column field="processDate" :header="$t('bets.processDate')" :sortable="true">
                            <template #body="slotProps">{{ formatCSDateAndTime(slotProps.data.processDate) }} </template>
                        </Column>
                        <Column field="payDate" :header="$t('bets.payDate')" :sortable="true">
                            <template #body="slotProps">{{ formatCSDateAndTime(slotProps.data.payDate) }} </template>
                        </Column>
                        <Column :header="$t('bets.payShop')" field="payShop" :sortable="true"> </Column>
                        <Column :header="$t('bets.paidBy')" field="paidBy" :sortable="true"> </Column>
                        <Column field="paidAmount" :header="$t('bets.paidAmount')" :sortable="true">
                            <template #body="slotProps">{{ formatCurrency(slotProps.data.paidAmount) }} </template>
                        </Column>
                        <Column :header="$t('reports.dayDifference')" field="daysDifference"> </Column>
                    </DataTable>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { BoReportsApi } from '../../../service/BoReportsApi';
import { BoSaveReportsApi } from '../../../service/BoSaveReportsApi';
import settings from '../../../settings/generalSettings';
export default {
    name: 'report',
    data() {
        return {
            reportData: [],
            BoReportsApi: new BoReportsApi(),
            BoSaveReportsApi: new BoSaveReportsApi(),
            isReportLoading: false,
            isReportDownloading: false,
            showReportData: false,
            startDateModel: null,
            endDateModel: null,
            errorKeyCount: 0,
            showError: false,
            daysNo: 5,
            disableSubmit: false,
            productModel: {},
        };
    },
    mounted() {
        let crtDate = new Date();
        let year = crtDate.getFullYear();
        let month = crtDate.getMonth() + 1;
        if (month <= 9) {
            month = '0' + month;
        }
        let day = crtDate.getDate();
        if (day <= 9) {
            day = '0' + day;
        }
        var startDateString = year + '-' + month + '-' + '01' + 'T00:00';
        var endDateString = year + '-' + month + '-' + day + 'T23:59';
        this.startDateModel = new Date(startDateString);
        this.endDateModel = new Date(endDateString);
        this.productModel = this.products[0];
    },
    computed: {
        products() {
            return [
                {
                    id: settings.products.sportsBetting,
                    name: this.$t('products.sportbets'),
                    code: 'SB',
                },
                {
                    id: settings.products.lotto,
                    name: this.$t('products.lotto'),
                    code: 'LT',
                },
            ];
        },
    },
    methods: {
        showReport() {
            if (this.notNullOrEmptyField(this.daysNo) && this.daysNo > 0) {
                if (this.notNullOrEmptyObject(this.productModel)) {
                    let dateDiff = (this.endDateModel.getTime() - this.startDateModel.getTime()) / (1000 * 3600 * 24);
                    if (dateDiff <= 31) {
                        this.showReportData = false;
                        this.isReportLoading = true;
                        this.BoReportsApi.getPaidTickets(this.formatEnDate(this.startDateModel), this.formatEnDate(this.endDateModel), this.productModel.code, this.daysNo)
                            .then((reportResponse) => {
                                if (reportResponse.data != null) {
                                    this.reportData = reportResponse.data;
                                    this.tableKey++;
                                }
                                this.isReportLoading = false;
                                this.showReportData = true;
                            })
                            .catch((error) => {
                                this.checkForUnauthorizedError(error);
                                this.showError = true;
                                this.errorKeyCount++;
                                this.isReportLoading = false;
                            });
                    } else {
                        this.showToast('error', this.$t('alerts.dateIntervalOneMonth'));
                    }
                } else {
                    this.showToast('error', this.$t('alerts.noProduct'));
                }
            } else {
                this.showToast('error', this.$t('alerts.negativeDays'));
            }
        },
        saveXls() {
            if (this.notNullOrEmptyField(this.daysNo) && this.daysNo > 0) {
                if (this.notNullOrEmptyObject(this.productModel)) {
                    let dateDiff = (this.endDateModel.getTime() - this.startDateModel.getTime()) / (1000 * 3600 * 24);
                    if (dateDiff <= 31) {
                        this.isReportDownloading = true;
                        this.BoSaveReportsApi.savePaidTickets(this.formatEnDate(this.startDateModel), this.formatEnDate(this.endDateModel), this.productModel.code, this.daysNo)
                            .then((response) => {
                                this.isReportDownloading = false;
                                let blob = new Blob([response.data], { type: 'application/xls' });
                                let link = document.createElement('a');
                                link.href = window.URL.createObjectURL(blob);
                                link.download = 'PaidTickets_' + this.formatEnDate(this.startDateModel) + '_' + this.formatEnDate(this.endDateModel) + '_' + this.productModel.code + '.xlsx';
                                link.click();
                            })
                            .catch((error) => {
                                this.checkForUnauthorizedError(error);
                                this.showError = true;
                                this.errorKeyCount++;
                                this.isReportDownloading = false;
                            });
                    } else {
                        this.showToast('error', this.$t('alerts.dateIntervalOneMonth'));
                    }
                } else {
                    this.showToast('error', this.$t('alerts.noProduct'));
                }
            } else {
                this.showToast('error', this.$t('alerts.negativeDays'));
            }
        },
        showToast(toastSeverity, text) {
            this.$toast.add({ severity: toastSeverity, summary: '', detail: text, life: this.getToastLifeSeconds() });
        },
    },
};
</script>